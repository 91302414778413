 const toggleTitle=[
    {
        id:0,
        title:"Introduction",
        exp:true
    },
    {
        id:1,
        title:"Lecture1"
    },
    {
        id:2,
        title:"Lecture2"
    },
    {
        id:3,
        title:"Lecture3"
    },
    {
        id:4,
        title:"Lecture4"
    },
    {
        id:5,
        title:"Lecture5"
    },
    {
        id:6,
        title:"Lecture6"
    },
    {
        id:7,
        title:"Lecture7"
    },
    {
        id:8,
        title:"Lecture8"
    },
    {
        id:9,
        title:"Lecture9"
    },
    {
        id:10,
        title:"Lecture10"
    },
    // {
    //     id:12,
    //     title:"Lecture12"
    // },
    // {
    //     id:13,
    //     title:"Lecture13"
    // },
    // {
    //     id:14,
    //     title:"Lecture14"
    // },
    // {
    //     id:15,
    //     title:"Lecture15"
    // },
    // {
    //     id:16,
    //     title:"Lecture16"
    // },
    // {
    //     id:17,
    //     title:"Lecture17"
    // },
    // {
    //     id:18,
    //     title:"Lecture18"
    // },
    // {
    //     id:19,
    //     title:"Lecture19"
    // },
    // {
    //     id:20,
    //     title:"Lecture20"
    // },
    // {
    //     id:21,
    //     title:"Lecture21"
    // },
    // {
    //     id:22,
    //     title:"Lecture22"
    // },
    // {
    //     id:23,
    //     title:"Lecture23"
    // },
    // {
    //     id:24,
    //     title:"Lecture24"
    // },
    // {
    //     id:25,
    //     title:"Lecture25"
    // },
    // {
    //     id:26,
    //     title:"Lecture26"
    // },
    // {
    //     id:27,
    //     title:"Lecture27"
    // },
    // {
    //     id:28,
    //     title:"Lecture28"
    // },
    // {
    //     id:29,
    //     title:"Lecture29"
    // },
    // {
    //     id:30,
    //     title:"Lecture20"
    // },
    // {
    //     id:31,
    //     title:"Lecture31"
    // },
    // {
    //     id:32,
    //     title:"Lecture32"
    // },
]


export default toggleTitle;