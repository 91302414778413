import React from 'react';
import Navbar from '../LandingPage/Navbar';
import Footer from '../LandingPage/Footer';

const Ailab = () => {
  return (
    <div>
        <Navbar/>
        <div className="page-title light-background">
      <div className="container">
      <h1>Empower Kids with AI and IoT Innovations!</h1>

        <nav className="breadcrumbs">
          <ol>
            <li><a href="index.html">Home</a></li>
            <li className="current">AI and IoT</li>
          </ol>
        </nav>
      </div>
    </div>

    


  <div class="container my-5">
    <div class="row">
   
      <div class="col-5 text-center">
        <img src="https://www.stemrobo.com/wp-content/uploads/2023/07/2.-AI-IOT-scaled.jpg" alt="Students working on STEM activities" class="img-fluid rounded"/>
      </div>
   

  
      <div class="col-7 text-justify">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
  <div style={{ width: '40px', height: '2px', backgroundColor: 'yellow', marginRight: '10px' }}></div>
  <h3 className="text-primary mb-4" style={{ margin: 0 }}>
  What is AI and IOT?
  </h3>
  <div style={{ width: '40px', height: '2px', backgroundColor: 'yellow', marginLeft: '10px' }}></div>
</div>

        <p className='fs-6'>
       
        Artificial Intelligence (AI) refers to the simulation of human-like intelligence in machines, enabling them to learn, reason, and make decisions. The Internet of Things (IoT) involves connecting everyday objects to the internet, enabling them to collect and exchange data. For kids, understanding AI and IoT is crucial as they interact with an increasingly interconnected world. These concepts offer insights into how technology shapes our lives, fostering digital literacy and preparing them for a future where AI and IoT will play pivotal roles. Knowledge of AI and IoT empowers kids to think critically about technology’s impact and harness its potential to solve complex problems, ensuring they become responsible and informed participants in the digital age, AI & Robotics Lab, Robotics and AI Lab setup in School and Colleges.
        </p>
        <div >
          <a href="/contact" class="btn btn-primary">Contact Us</a>
        </div>
      </div>
 </div>
  </div>

  <section id="starter-section" class="starter-section section bg-light">
  <div className='container mt-4 mb-4'>
     <div className='row'>
      <div className='col-8 text-justify px-4'>
          <h1>Setup AI and IoT Lab in Your School</h1>
          <p className='fs-6 mt-4'>Your one-stop destination for innovative AI and IoT education! We provide top-notch learning experiences through our state-of-the-art labs and comprehensive training programs. Our AI and IoT Labs for Schools are equipped with cutting-edge technology, designed to foster interdisciplinary learning and critical thinking skills among students. <br/>
  
  At STEMROBO, we understand the significance of integrating AI and IoT into education. Our AI and IoT Lab for Schools offers a unique approach that combines artificial intelligence, machine learning, and the Internet of Things, empowering students to develop a well-rounded skill set necessary for the 21st-century workforce. Through hands-on activities, students explore the world of AI, set up IoT systems, and learn how to design and program AI-powered devices and solutions. <br/>
  
  Our AI and IoT Lab with Training is specifically designed to cater to K-12 students. We offer a wide range of courses that ignite curiosity and promote experiential learning. Our learning programs encourage students to think outside the box and turn their creative ideas into reality. With AI and IoT at the core, we provide a platform for young minds to explore their potential and nurture their dreams. STEMROBO is a leading AI and IoT company committed to providing excellence in education. We offer learning franchises, allowing educational institutions to partner with us and benefit from our expertise and resources. Our team of experienced consultants is dedicated to supporting your institution in implementing effective programs that enhance student engagement and achievement. As one of the top AI and IoT education companies, STEMROBO takes pride in our state-of-the-art innovation and learning center. It serves as a hub for creativity, exploration, and discovery, fostering a culture of innovation and critical thinking among students. Our lab setups in schools provide a stimulating environment for hands-on learning, where students can immerse themselves in the fascinating world of AI and IoT.</p>
      </div>
      <div className='col-4 mt-4'>
          <img src='https://ai.stanford.edu/wp-content/uploads/2022/08/sail_lab-big.jpg' height={"480px"} width={"400px"}/>
      </div>
     </div>
     <div className='row'>
      <div className='col-12 text-justify px-4'>
      <p className='fs-6'>
          We believe that AI and IoT are not limited to adults but are also an exciting and educational journey for kids. Our AI and IoT programs are tailored to the needs and interests of young learners, providing them with an engaging platform to develop their skills and ignite their passion for technology and innovation. With AI and IoT lab setups in schools, we ensure that students have access to cutting-edge resources and guidance to nurture their curiosity.
        </p>
        <p className='fs-6'>
          At STEMROBO, we offer AI and IoT labs that go hand in hand. Our AI and IoT Lab is a unique facility where students can explore the synergies between these two transformative fields. We provide a comprehensive AI and IoT curriculum for schools, equipping students with the knowledge and skills required to thrive in the digital age.
        </p>
        <p className='fs-6'>
          Our curriculum is carefully designed to align with educational standards and cater to the specific needs of students at different levels. Through our AI & IoT lab for students, we provide a dynamic learning environment where students can experiment, collaborate, and innovate.
        </p>
        <p className='fs-6'>
          STEMROBO is your trusted partner in establishing AI and IoT labs for schools. Our lab setups empower educational institutions to create an immersive learning experience for their students. With our expertise and support, your school can become a hub of innovation and technological advancement.
        </p>
        <p className='fs-6'>
          As an AI and IoT lab company, we are dedicated to shaping the future of education through transformative technologies. We believe in the power of AI and IoT to revolutionize learning and prepare students for the challenges of tomorrow. Join us on this exciting journey of exploration and discovery at STEMROBO!
        </p>
      </div>
     </div>
  </div>
</section>




  <div className="container mt-5">
  
    <h3 className="text-primary mb-4 text-center">
      KITS WITH INTEGRATED CURRICULUM FOR AI & IoT LAB
    </h3>
  
    <div className="row">
  
      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <img
          src="https://www.stemrobo.com/wp-content/uploads/2023/08/robotic-kit.jpg"
          alt="AI & IoT 1"
          className="img-fluid rounded shadow"
        />
      </div>
  
      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <img
          src="https://www.stemrobo.com/wp-content/uploads/2023/08/stem-paper-circuit.jpg"
          alt="AI & IoT 2"
          className="img-fluid rounded shadow"
        />
      </div>
  
      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <img
          src="https://www.stemrobo.com/wp-content/uploads/2023/08/Aurdino-robotic-kit.jpg"
          alt="AI & IoT 3"
          className="img-fluid rounded shadow"
        />
      </div>
  
      <div className="col-12 col-sm-6 col-md-3 mb-4">
        <img
          src="https://www.stemrobo.com/wp-content/uploads/2023/08/stembot.jpg"
          alt="AI & IoT 4"
          className="img-fluid rounded shadow"
        />
      </div>
    </div>
  </div>

<section class="bg-light py-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7">
        <h3 class="fs-6 mb-2 text-secondary text-center text-uppercase">Our Expertise</h3>
        <h2 class="display-5 mb-5 text-center">We excel in providing top-notch skills for your success.</h2>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container overflow-hidden">
    <div class="row gy-4 gy-xl-0">
  
      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-code text-primary mb-4" viewBox="0 0 16 16">
              <path d="M5.719 3.858a.5.5 0 0 0-.638-.058L.58 7.42a.5.5 0 0 0 0 .758l4.5 3.5a.5.5 0 1 0 .638-.766L1.545 8 5.719 4.624a.5.5 0 0 0 .058-.766ZM10.281 3.858a.5.5 0 0 1 .638-.058l4.5 3.5a.5.5 0 0 1 0 .758l-4.5 3.5a.5.5 0 1 1-.638-.766L14.455 8 10.281 4.624a.5.5 0 0 1-.058-.766Z" />
              <path d="M6.854 11.146a.5.5 0 0 1 0 .708l-1 1a.5.5 0 0 1-.708-.708l1-1a.5.5 0 0 1 .708 0ZM9.146 4.854a.5.5 0 0 1 0-.708l1-1a.5.5 0 1 1 .708.708l-1 1a.5.5 0 0 1-.708 0Z" />
            </svg>
            <h4 class="mb-4">Programming</h4>
            <p class="mb-4 text-secondary">Expertise in modern programming languages like JavaScript, Python, and Java to build innovative solutions.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-bar-chart text-primary mb-4" viewBox="0 0 16 16">
              <path d="M0 0h1v15h15v1H0V0Zm10 5h2v8h-2V5ZM6 8h2v5H6V8ZM2 11h2v2H2v-2Z" />
            </svg>
            <h4 class="mb-4">Data Analysis</h4>
            <p class="mb-4 text-secondary">Specialized in analyzing complex datasets to derive actionable insights and drive strategic decisions.</p>
          </div>
        </div>
      </div>

      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-brush text-primary mb-4" viewBox="0 0 16 16">
              <path d="M15.825.14c-.646-.646-2.313.38-4.1 2.168-.272.271-.516.539-.733.802-1.62-1.273-3.553-1.504-4.91-.148-1.19 1.19-.97 3.219.242 4.968L.563 12.512a.25.25 0 0 0-.045.253c.302.756.548 1.374.677 1.673.143.33.347.56.58.663.244.107.521.101.92-.013.308-.09.654-.229 1.024-.403a.255.255 0 0 0 .09-.378L7.392 7.6c1.75 1.21 3.777 1.432 4.969.241 1.356-1.356 1.125-3.289-.148-4.91.263-.217.53-.46.802-.732 1.787-1.788 2.813-3.454 2.168-4.1ZM2.507 13.934c-.244.084-.468.152-.664.204.109-.196.2-.42.285-.657l.037-.1c.049-.133.097-.265.145-.388l.197.197c-.46.248-.592.324-.637.344ZM1.05 15h-.03.03Zm.005-.001.016.001H1.05Zm.022.002h-.023.023Z" />
            </svg>
            <h4 class="mb-4">Creative Design</h4>
            <p class="mb-4 text-secondary">Delivering stunning and user-centric designs for web, mobile, and print media that leave a lasting impression.</p>
          </div>
        </div>
      </div>
    
      <div class="col-12 col-sm-6 col-xl-3">
        <div class="card border-0 border-bottom border-primary shadow-sm">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-shield-lock text-primary mb-4" viewBox="0 0 16 16">
              <path d="M5.072 11.293a.5.5 0 0 0 .708-.707 3 3 0 1 1 4.24 0 .5.5 0 1 0 .708.707 4 4 0 1 0-5.656 0ZM8 4a2 2 0 0 1 1.716 3.008A2.99 2.99 0 0 1 8 6a2.99 2.99 0 0 1-1.716.992A2 2 0 0 1 8 4Z" />
              <path d="M8 0c-.69 0-1.382.04-2.073.115C4.224.26 3.443.525 2.854.9a4.001 4.001 0 0 0-1.878 3.053C.497 5.98 0 8.013 0 10.124c0 4.124 4.167 5.907 8 5.907s8-1.783 8-5.907c0-2.11-.497-4.144-1.975-6.171A4.001 4.001 0 0 0 13.146.9c-.59-.375-1.37-.64-2.073-.785A25.362 25.362 0 0 0 8 0Zm.52 11.743a3.522 3.522 0 0 1-1.04 0 3.496 3.496 0 0 1-.52-6.925V2.757a5.977 5.977 0 0 1 1.56 0v2.061a3.497 3.497 0 0 1-.52 6.925Z" />
            </svg>
            <h4 class="mb-4">Cybersecurity</h4>
            <p class="mb-4 text-secondary">Ensuring secure systems by implementing best practices in cybersecurity and vulnerability management.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



<section class="bsb-faq-3 py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-11 col-md-11 col-lg-11 col-xl-11 col-xxl-11">
        <h2 class="mb-4 display-5 text-center text-primary">Frequently Asked Questions </h2>
        <p class="text-secondary text-center lead fs-4 px-4">Welcome to our FAQ page, where we answer your most commonly asked questions about AI & IoT, fields that combine advanced technologies to create interconnected systems that are revolutionizing industries.</p>
        <p class="mb-5 text-center">Whether you're new to AI & IoT or a seasoned enthusiast, this page will provide you with useful insights and answers to your most pressing questions.</p>
       
      </div>
    </div>
  </div>

  <div class="mb-8">
    <div class="container">
      <div class="row justify-content-center">
     
        <div class="col-8 col-xl-8">
          <div class="accordion accordion-flush" id="faqAI">
            <div class="accordion-item bg-transparent border-top border-bottom py-3">
              <h2 class="accordion-header" id="faqAIHeading1">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAICollapse1" aria-expanded="false" aria-controls="faqAICollapse1">
                  What is AI & IoT?
                </button>
              </h2>
              <div id="faqAICollapse1" class="accordion-collapse collapse" aria-labelledby="faqAIHeading1">
                <div class="accordion-body">
                  <p>AI (Artificial Intelligence) refers to the simulation of human intelligence in machines, while IoT (Internet of Things) connects devices through the internet, enabling them to communicate and share data. Together, they create intelligent, connected systems for real-world applications.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAIHeading2">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAICollapse2" aria-expanded="false" aria-controls="faqAICollapse2">
                  How can I get started with AI & IoT?
                </button>
              </h2>
              <div id="faqAICollapse2" class="accordion-collapse collapse" aria-labelledby="faqAIHeading2">
                <div class="accordion-body">
                  <p>Start by learning the basics of AI and IoT through online courses, books, and hands-on projects. Exploring platforms like Arduino, Raspberry Pi, and TensorFlow is a great way to get hands-on experience with IoT systems and AI programming.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAIHeading3">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAICollapse3" aria-expanded="false" aria-controls="faqAICollapse3">
                  What skills are required for AI & IoT?
                </button>
              </h2>
              <div id="faqAICollapse3" class="accordion-collapse collapse" aria-labelledby="faqAIHeading3">
                <div class="accordion-body">
                  <p>Key skills include programming (Python, C++), data analysis, machine learning, cloud computing, electronics, and networking. Understanding IoT protocols and having the creativity to design intelligent systems are also critical for success in these fields.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAIHeading4">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAICollapse4" aria-expanded="false" aria-controls="faqAICollapse4">
                  What are some real-world applications of AI & IoT?
                </button>
              </h2>
              <div id="faqAICollapse4" class="accordion-collapse collapse" aria-labelledby="faqAIHeading4">
                <div class="accordion-body">
                  <p>AI & IoT are used in smart homes, autonomous vehicles, healthcare (smart medical devices), industrial automation, smart cities, and agriculture (precision farming). They create intelligent, connected systems that improve efficiency and decision-making.</p>
                </div>
              </div>
            </div>

            <div class="accordion-item bg-transparent border-bottom py-3">
              <h2 class="accordion-header" id="faqAIHeading5">
                <button class="accordion-button collapsed bg-transparent fw-bold shadow-none link-primary" type="button" data-bs-toggle="collapse" data-bs-target="#faqAICollapse5" aria-expanded="false" aria-controls="faqAICollapse5">
                  How do AI & IoT competitions work?
                </button>
              </h2>
              <div id="faqAICollapse5" class="accordion-collapse collapse" aria-labelledby="faqAIHeading5">
                <div class="accordion-body">
                  <p>Competitions involve teams designing AI-driven systems or IoT solutions to solve specific challenges. These events test participants' ability to create innovative solutions that integrate machine learning, sensors, and real-time data for real-world applications.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="starter-section" class="starter-section section">
    <div class="hero-container" id="hero-sec">
      <div class="container">
        <div class="row d-flex">
          <div class="col align-middle">
            <div class="px-2 py-2">
              <img src="https://img.freepik.com/free-vector/happy-freelancer-with-computer-home-young-man-sitting-armchair-using-laptop-chatting-online-smiling-vector-illustration-distance-work-online-learning-freelance_74855-8401.jpg?w=900&t=st=1667037491~exp=1667038091~hmac=7c71ea8afc8f3cc8065c5ccc05d105e3c8a7b76f0133016cb210a7882dc19611" class="img-fluid" alt="..."/>
            </div>
          </div>
          <div class="col">
            <div class="px-5 py-5 mt-5">
              <div class="px-2 py-2 align-middle">
                <h4>Get all your needs Here</h4>
                <p>An online learning and teaching marketplace with over 204000 courses and 54 million students. Learn programming, marketing, data science, and more.</p>
              </div>
              <div class="px-2 py-2">
                <a href="/kits" class="btn btn-outline-primary">Checkout Our Products</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<Footer/>
  </div>
  )
}

export default Ailab