import React from 'react';
import BlocklyComponent from './Blockly-yjs';

const Blockly2 = () => {
  return (
    <div className="App">
      <BlocklyComponent />
    </div>
  );
};

export default Blockly2;